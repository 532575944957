import { useEffect, useRef, useState } from "react";
import UseSentMutation from "../api/mutation/useSentMutation";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import markdownItHighlight from "markdown-it-highlightjs";
import Bot from "../assest/svg/Bot.svg";
import User from "../assest/svg/User.svg";
import SidebarOpen from "../assest/svg/sidebar_open.svg";
import { Sidebar } from "../components/common/Sidebar";
import { useAllChatsQuery } from "../api/query/useAllChatsQuery";
import { ChatType } from "../api/types/allChatType";
import { useChatByIdQuery } from "../api/query/useChatByIdQuery";
import { Input } from "../components/common/Input";
import ThemeSwitch from "../components/darkToggle";

export type Message = {
  role: string;
  conversation_id: string;
  timestamp: string;
  content: string;
  image_url: any;
  id: string;
};

const ChatUI = () => {
  const [loading, setLoading] = useState(false);
  const [tempId, setTempId] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [firstMessage, setFirstMessage] = useState<ChatType[]>([]);
  const [input, setInput] = useState("");
  const [image, setImage] = useState<File | null>(null);
  // const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [selectedMessageThreadId, setSelectedMessageThreadId] =
    useState<string>("");
  const getChatQuery = useChatByIdQuery(
    selectedMessageThreadId ?? "",
    Boolean(selectedMessageThreadId)
  );
  const fetchAllChat = useAllChatsQuery();
  const getChats = fetchAllChat.data?.data.data;

  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const updateThreadId = (thread_id: string) => {
    setSelectedMessageThreadId(thread_id);
  };

  const handleNewChat = () => {
    setMessages([]);
    setSelectedMessageThreadId("");
  };

  const handleMessage = (message: string) => {
    if (loading) {
      setLoading(false);
    }
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) =>
        msg.id === tempId ? { ...msg, content: msg.content + message } : msg
      );
      if (!updatedMessages.some((msg) => msg.id === tempId)) {
        updatedMessages.push({
          content: message,
          role: "system",
          conversation_id: "",
          timestamp: "",
          id: tempId,
          image_url: "",
        });
      }

      return updatedMessages;
    });
  };

  const handleError = (error: any) => {
    console.log("Streaming Error:", error);
  };

  const mutation = UseSentMutation(handleMessage, handleError);

  const handleSend = () => {
    setTempId(crypto.randomUUID());
    setLoading(true);
    if (input.trim()) {
      const formData = new FormData();
      if (image) {
        formData.append("image", image);
      }
      formData.append("prompt", input);
      if (selectedMessageThreadId) {
        formData.append("thread_id", selectedMessageThreadId);
      }
      mutation.mutate(formData, {
        onSuccess(data) {
          fetchAllChat.refetch().then((res) => {
            setFirstMessage(
              res.data?.data.data.sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              ) ?? []
            );
          });
        },
        onError(error) {
          console.log("Mutation error:", error);
        },
      });

      // Reset input and add user message to the list
      setMessages([
        ...messages,
        {
          content: input,
          role: "user",
          conversation_id: "",
          timestamp: "",
          id: "",
          image_url: image ? URL.createObjectURL(image) : "",
        },
      ]);
      setInput("");
      setImage(null);
    }
  };
  const decodeText = (inputText: string): string => {
    try {
      const md = MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        breaks: true,
        highlight: (str: string, lang: string): string => {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(str, {
                language: lang,
              }).value;
            } catch (__) {
              console.error("Error highlighting code:", __);
            }
          }
          return md.utils.escapeHtml(str);
        },
      });
      md.use(markdownItHighlight);

      const parts = inputText.split(/(```[\s\S]*?```)/g);

      const processedParts = parts.map((part) => {
        if (part.startsWith("```")) {
          return part;
        } else {
          return part.replace(/\n\n/g, "\n&#8203;\n");
        }
      });

      let processedInput = processedParts.join("");

      const jsonMatch = processedInput.match(/\{.*?\}/);

      if (jsonMatch) {
        const data = JSON.parse(jsonMatch[0]);
        if (!selectedMessageThreadId) {
          setSelectedMessageThreadId(data.thread_id);
        }
        processedInput = processedInput.replace(jsonMatch[0], "").trim();
      }
      const renderedHtml = md.render(processedInput, "markdown");
      return renderedHtml;
    } catch (error) {
      console.log(error);
      return `${error}`;
    }
  };

  useEffect(() => {
    return setMessages(
      getChatQuery.data?.data.data ?? []
       
    );
  }, [getChatQuery.data?.data.data ]);

  const chatBox = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setTimeout(() => {
      if (chatBox.current && messages.length) {
        chatBox.current.scroll({
          top: chatBox.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  }, [messages]);

  useEffect(() => {
    setFirstMessage(
      getChats?.sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      ) ?? []
    );
  }, [getChats]);

  return (
    <div className="flex items-center h-full w-full scroll ">
      <div
        className={`h-full ${
          isSidebarOpen ? "w-[20%]" : "w-0"
        } transition-all duration-300 overflow-hidden`}
      >
        
        <Sidebar
          messages={firstMessage}
          thread_id={selectedMessageThreadId}
          onClose={handleSidebar}
          selectedChatId={updateThreadId}
          newChat={handleNewChat}
        />
      </div>
    
      <div
        className={`h-full ${
          isSidebarOpen ? "w-[85%]" : "w-full"
        } transition-all duration-300`}
      > 
       {/* <div className="flex justify-end mt-10 mr-10">
        <ThemeSwitch />
      </div> */}
        <div className="flex p-3 justify-center items-start h-full">
          {!isSidebarOpen && (
            <button
              onClick={handleSidebar}
              className="p-2 w-[5%] flex justify-center items-center"
            >
              <div className="w-8 h-8">
                <img
                  className="w-full h-full align-middle "
                  src={SidebarOpen}
                  alt=""
                />
              </div>
            </button>
          )}
          <div className="flex flex-col mx-auto rounded-lg h-[95vh] pb-2 pt-5 !w-[60%] justify-center overflow-hidden relative">
            <div className="flex-1 overflow-y-auto p-4 px-5 mb-8" ref={chatBox}>
              {messages.map((message, index) =>
                message.role === "user" ? (
                  <div
                    className="flex gap-2 items-end justify-center"
                    key={index}
                  >
                    <div
                      key={index}
                      className={`p-2 rounded-lg ml-auto shadow-lg w-fit text-gray-800 max-w-[70%] ${
                        message.role === "user"
                          ? "bg-[#ebf3fe] text-gray-800  self-end"
                          : "bg-[#f9f9fb] text-black self-start"
                      }`}
                    >
                      {message.image_url ? (
                        <div className="flex flex-col gap-2">
                          <div className="w-40 h-40 rounded-lg">
                            <img
                              className="w-full h-full align-middle rounded-lg"
                              src={message.image_url}
                              alt="image"
                            />
                          </div>
                          <div className="content">{message.content}</div>
                        </div>
                      ) : (
                        <>{message.content}</>
                      )}
                    </div>
                    <div className="bg-[#ebf3fe] p-2 rounded-lg shadow-lg">
                      <img src={User} className="w-6 max-w-6" alt="user" />
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="flex justify-start items-end gap-2 text-gray-800 my-5"
                  >
                    {
                      <div className="bg-[#f9f9fb] p-2 rounded-lg shadow-lg">
                        <img src={Bot} className="w-6 max-w-6" alt="user" />
                      </div>
                    }
                    <div
                      key={message.content}
                      className="w-[70%]  p-2 shadow-lg rounded-md  bg-[#f9f9fb]"
                      dangerouslySetInnerHTML={{
                        __html: decodeText(message.content),
                      }}
                    ></div>
                  </div>
                )
              )}
            </div>

            {/* <div className="px-1.5">
              <div
                className={`bg-white rounded-t-2xl ${
                  image ? "pb-3.5 border" : "p-0"
                }`}
              >
                {image && (
                  <div className="relative w-28 h-20 top-2 mx-4 group">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="uploaded"
                      className="w-full h-full rounded-xl"
                    />
                    <img
                      src={Cancel}
                      alt="cancel"
                      onClick={() => setImage(null)}
                      className="z-10 absolute -top-2 -right-2 w-5 opacity-0 group-hover:opacity-100 transition-opacity bg-white rounded-full border-[0.5px] border-white"
                    />
                  </div>
                )}
              </div>
              <div
                className={`bg-white flex items-center justify-center gap-2 ${
                  image ? "rounded-b-2xl" : "rounded-full"
                } w-full`}
              >
                <input
                  type="text"
                  className="flex-1 p-4 rounded-full w-full focus:outline-none disabled:bg-white h-14"
                  value={input}
                  disabled={
                    mutation.isPending || uploadImgaeMutation.isPending
                      ? true
                      : false
                  }
                  placeholder="Ask your question here"
                  onChange={(e) => {setInput(e.target.value); autoResizeTextarea()}}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && input) handleSend();
                  }}
                />
                <input
                  type="file"
                  accept="image/*"
                  className="p-2 hidden"
                  disabled={
                    mutation.isPending || uploadImgaeMutation.isPending
                      ? true
                      : false
                  }
                  onChange={(e) =>
                    e.target.files ? setImage(e.target.files[0]) : ""
                  }
                  ref={inputFileRef}
                />
                <button
                  onClick={() => inputFileRef.current?.click()}
                  className={`w-5 h-5 rounded-full`}
                >
                  <img
                    className={`w-full align-middle h-full`}
                    src={image ? ClipEngage : Clip}
                    alt="attach"
                  />
                </button>
                <button
                  className="p-2 text-gray-800 rounded-lg opacity-100 disabled:opacity-50"
                  onClick={handleSend}
                  disabled={Boolean(!input)}
                >
                  <img className="w-6 max-w-6" src={Send} alt="send" />
                </button>
              </div>
            </div> */}
            {/* <Typewriter message="How can I assist you.?" speed={100} /> */}
            <div
              className={`transition-all duration-200 ease-in-out w-full ${
                selectedMessageThreadId
                  ? "absolute bottom-0 left-1/2 transform -translate-x-1/2 "
                  : "absolute bottom-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              }`}
            >
              {loading && (
                <div className="flex gap-2 items-center">
                  <svg height="40" width="40" className="loader ml-5">
                    <circle
                      className="dot"
                      cx="10"
                      cy="20"
                      r="3"
                      fill="#ebf3fe"
                    />
                    <circle
                      className="dot"
                      cx="20"
                      cy="20"
                      r="3"
                      fill="#ebf3fe"
                    />
                    <circle
                      className="dot"
                      cx="30"
                      cy="20"
                      r="3"
                      fill="#ebf3fe"
                    />
                  </svg>
                </div>
              )}
              <Input
                loading={mutation.isPending}
                onSubmit={handleSend}
                setInput={setInput}
                setImage={setImage}
                input={input}
                image={image}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatUI;
