import axios from "axios";
import { baseURL } from "../Environment";

const ApiRequest = axios.create({
  baseURL,
});

ApiRequest.interceptors.request.use((config) => {
  return config;
});

ApiRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiRequest;
