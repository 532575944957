import { useQuery } from "@tanstack/react-query";
import { requestURLs } from "../../Environment";
import { QUERY_KEYS } from "./querykeys";
import {
  AxiosResponseAndError,
  ResponseType,
} from "../types/axiosResponseType";
import ApiRequest from "../ApiRequest";


export type ChatResponse = ResponseType<any[]>;

export const useChatByIdQuery = (chatId:string|undefined, enabled = false) => {
  return useQuery<
    AxiosResponseAndError<ChatResponse>["response"],
    AxiosResponseAndError<ChatResponse>["error"]
  >({
    queryKey: [QUERY_KEYS.chatId, chatId],
    queryFn: async () =>
      await ApiRequest.get(`${requestURLs.getChat}/${chatId}`),
    enabled: enabled,
  });
}