import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { requestURLs } from "../../Environment";

type SentData = FormData;

type OnMessageHandler = (message: string) => void;
type OnErrorHandler = (error: any) => void;

function UseSentMutation(
  onMessage: OnMessageHandler,
  onError: OnErrorHandler
): UseMutationResult<any, any, SentData> {
  const mutation = useMutation<any, any, SentData>({
    mutationFn: async (data: SentData) => {
      try {
        const response = await fetch(requestURLs.sentLive, {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
          },
          body: data // Send the prompt in the body
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body?.getReader(); // Use a reader for streaming

        if (!reader) {
          throw new Error("No readable stream found in the response.");
        }

        const decoder = new TextDecoder();

        // Handle the stream data
        let done = false;
        while (!done) {
          const { value, done: isDone } = await reader.read();
          done = isDone;
          if (value) {
            const decodedValue = decoder.decode(value, { stream: true });
            onMessage(decodedValue); // Pass each chunk of data to the onMessage handler
          }
        }
        reader.releaseLock(); // Release the reader after completing the stream
      } catch (error) {
        onError(error);
        throw error; // To handle the error in react-query
      }
    },
  });

  return mutation;
}

export default UseSentMutation;
