import SidebarClose from "../../assest/svg/sidebar_close.svg";
import NewChat from "../../assest/svg/new_chat.svg";
import { ChatType } from "../../api/types/allChatType";

interface Sidebar {
  messages: ChatType[];
  thread_id: string;
  onClose: () => void;
  selectedChatId: (thread_id: string) => void;
  newChat: () => void;
}

export const Sidebar = (data: Sidebar) => {
  const { messages, thread_id, onClose, selectedChatId, newChat } = data;

  return (
    <div className={"p-3 bg-slate-50 h-full shadow-lg font-medium flex-col grow dark:bg-custom-dark-sidebar  "}>
      <div className="flex items-center justify-between w-full mb-6">
        <button onClick={onClose} className="w-8 h-8 ">
          <img
            className="w-full h-full align-middle"
            src={SidebarClose}
            alt=""
          />
        </button>
        <button className="w-8 h-8 " onClick={newChat}>
          <img className="w-full h-full align-middle" src={NewChat} alt="" />
        </button>
      </div>
      <div className="group">
        <button
          type="button"
          className="mb-6 p-2.5 w-full group-hover:bg-slate-50 rounded-lg flex justify-between items-center border-2 border-gray-400/20 dark:invert"
          onClick={newChat}
        >
          Start New Chat
          <img
            className="w-6 min-w-6 "
            src={NewChat}
            alt=""
          />
        </button>
      </div>
      <div className="flex flex-col overflow-y-auto h-[85%]">
        {messages &&
          messages.map((chat: ChatType, index: number) => {
            return (
              <div
                className={`rounded-lg p-3 mx-3 cursor-pointer hover:scale-105 transition-all duration-300 hover:shadow ${
                  chat.thread_id === thread_id
                    ? "bg-slate-100 text-gray-800 shadow-lg transition-all duration-300 border-2 border-gray-200/20"
                    : "border-2 border-gray-200/20 transition-all duration-300"
                }`}
                key={index}
                onClick={() => {
                  selectedChatId(chat.thread_id);
                }}
              >
                <div className="truncate whitespace-nowrap text-sm overflow-hidden">
                  {chat.first_message}
                </div>
              </div>
            );
          })}
      </div>
      {/* </div> */}
    </div>
  );
};
