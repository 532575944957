import { useRef, useState } from "react";
import Cancel from "../../assest/svg/cancel.svg";
import Clip from "../../assest/svg/clip.svg";
import ClipEngage from "../../assest/svg/clip_engage.svg";
import Send from "../../assest/svg/send.svg";

type InputProps = {
  onSubmit: (message: string) => void;
  loading: boolean;
  disabled?: boolean;
  setInput: (input: string) => void;
  setImage: (image: File | null) => void;
  input: string;
  image: File | null;
};

export const Input = (data: InputProps) => {
  const { loading, onSubmit, setInput, setImage, input, image } = data;
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const handleSend = () => {
    if (input.trim()) {
      onSubmit(input); // Call parent onSubmit handler
      setInput(""); // Reset input
      setImage(null); // Reset image
    }
  };

  return (
    <div className={`bg-transparent font-[500] m-auto w-full border-2 ${image ? "rounded-2xl" : "rounded-full"}  border-gray-600/20` }>
      {image && (
        <div className="relative w-28 h-20 top-2 mx-4 group border">
          <img
            className="w-full h-full rounded-xl"
            src={URL.createObjectURL(image)}
            alt=""
          />
          <img
            src={Cancel}
            alt="cancel"
            onClick={() => setImage(null)}
            className="z-10 absolute -top-2 -right-2 w-5 opacity-0 group-hover:opacity-100 transition-opacity bg-white rounded-full border-[0.5px] border-white"
          />
        </div>
      )}
      <div
        className={`flex items-center justify-center gap-2  text-lg ${
          image ? "rounded-b-2xl" : "rounded-full"
        } w-full`}
      >
        <input
          type="text"
          className="flex-1 px-4 rounded-full w-full focus:outline-none h-12"
          value={input}
          disabled={loading ? true : false}
          placeholder="Ask your question here"
          onChange={(e) => {
            setInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && input) handleSend();
          }}
        />
        <input
          type="file"
          accept="image/*"
          className="p-2 hidden bg-transparent"
          disabled={loading ? true : false}
          onChange={(e) => (e.target.files ? setImage(e.target.files[0]) : "")}
          ref={inputFileRef}
        />
        <button
          onClick={() => inputFileRef.current?.click()}
          className={`w-6 h-6 rounded-full opacity-50 disabled:opacity-50`}
        >
          <img
            className={`w-full align-middle h-full `}
            src={image ? ClipEngage : Clip}
            alt="attach"
          />
        </button>
        <button
          className="p-2 text-gray-800 rounded-lg opacity-75 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleSend}
          disabled={Boolean(!input)}
        >
          <img className="w-6 max-w-6 " src={Send} alt="send" />
        </button>
      </div>
    </div>
  );
};
