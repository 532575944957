import { useQuery } from "@tanstack/react-query";
import { AxiosResponseAndError, ResponseType } from "../types/axiosResponseType";
import { QUERY_KEYS } from "./querykeys";
import ApiRequest from "../ApiRequest";
import { requestURLs } from "../../Environment";
import { ChatType } from "../types/allChatType";

type GetChatType = ResponseType<ChatType[]>

export const useAllChatsQuery = () =>
  useQuery<
    AxiosResponseAndError<GetChatType>["response"],
    AxiosResponseAndError<GetChatType>["error"]
  >({
    queryKey: [QUERY_KEYS.allChat],
    queryFn: async () => await ApiRequest.get(requestURLs.getAllChat),
    enabled: true,
  });
